import React, { useEffect, useState, useRef } from "react";
import MyReactImageMagnify from "./MyReactImageMagnify";
import Slider from "react-slick";
import categoryimg from "../../images/categoryimg1.png";
import measuringGuideWomen from "../../images/measuringGuideWomen.png";
import sizeGuideCentimeters from "../../images/womensizeguide.png";
import sizeGuideInches from "../../images/sizeGuide.jpg";
import cm_Chart from "../../images/cm_Chart.jpg";
import Footer from "../layout/Footer/Footer";
import Header from "../layout/Header/Header";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, addItemsToWishlist } from "../../actions/cartAction";
import { Link } from "react-router-dom";
import CustomModal from "../layout/CustomModal";
import { loadingState } from "../../actions/userAction";
import {
  FacebookShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";

const SingleProduct = ({ props, match, location }) => {
  const dispatch = useDispatch();
  const excludedCategories = ["Earrings", "HandBags", "Clutches", "Necklace Sets", "Turban"];
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [product, setProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isSpecifications, setSepcificationsOpen] = useState(false);
  const [isShipping, setShippingOpen] = useState(false);
  const [isSizeOpen, setSizeOpen] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [colorVariants, setColorVariants] = useState([]);
  const [isSizeModalOpen, setSizeModalOpen] = useState(false);
  const [modalactiveTab, setModalActiveTab] = useState("tab1");
  const [RattingValue, setRattingValue] = useState(4);
  const [file, setFile] = useState();
  const [titleReview, setTitleReview] = useState("");
  const [messageReview, setMessageReview] = useState("");
  const [showReview, setShowReview] = useState(false);
  const [isCentimeters, setIsCentimeters] = useState(false); // Initial state set to inches
  const [selectedSize, setSelectedSize] = useState();
  // const [turbanSelectedSize, setTurbanSelectedSize] = useState(1);
  // const [turbanPico, setTurbanPico] = useState(50);

  const { cartItems, wishlist } = useSelector((state) => state.cart);
  const cart_ids = cartItems.map((obj) => obj.product_id);
  const wishlist_ids = wishlist.map((obj) => obj.product_id);

  const [selections, setSelections] = useState(
    match?.params?.cid === "Turban" ?
      {
        turbanLength: 1,
        Pico: 50,
      } : {
        lehengaWaist: "",
        lehengaLength: "",
        height: "",
        sleeveLength: "",
        padding: "",
        sareeLength: "",
        sareeWaist: "",
        pettiCoat: ""
      });

  const handleChange_customization = (e, field) => {
    setSelections({
      ...selections,
      [field]: e.target.value,
    });
  };

  useEffect(() => {
    localStorage.setItem(
      "basicCustomization",
      JSON.stringify({ id: match.params.pid, selections })
    );
  }, [selections]);

  const toggleUnits = () => {
    setIsCentimeters((prevState) => !prevState); // Toggles the state between true and false
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const modalRef = useRef(null);
  const openModal = () => {
    setSizeModalOpen(true);
  };

  const closeModal = () => {
    setSizeModalOpen(false);
  };

  const changeTab = (tab) => {
    setModalActiveTab(tab);
  };

  const history = useHistory();

  const handleClick = (url) => {
    history.push(url);
  };

  function decreaseQuantity() {
    if (quantity > 1) setQuantity(quantity - 1);
  }
  function increaseQuantity() {
    if (quantity < product.quantity) setQuantity(quantity + 1);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function getFormattedDate(timestamp) {
    const date = new Date(timestamp);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
  const encodedUrl =
    "https://www.vastrakala.com" + (location.pathname).replace(/ /g, "%20");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getProduct(id, cid = null) {
    dispatch(loadingState(true));
    if (!cid) {
      fetch(`/api/products/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data?.product) {
            setProducts(data.product);
            setRelatedProducts(data.related);
            setReviews(data.reviews);
            setSizes(data.sizes);
            setColorVariants(data.colorVariants);
            setSelectedMedia(
              process.env.REACT_APP_BASE_URL + data.product.image
            );
            dispatch(loadingState(false));
            setSelectedSize(data.product.size);
            if (isAuthenticated)
              fetch(`/api/admin/products/review/${data.product.parent_id}`)
                .then((response) => response.json())
                .then((data) => {
                  if (data.length)
                    setShowReview(true);
                  else
                    setShowReview(false);
                }).catch((e) => {
                  setShowReview(false);
                });
          } else {
            setPopupContent2("Please fill all values.");
            setShowPopup2(true);
          }
        });
    } else {
      fetch(`/api/products/${id}/${cid}`)
        .then((response) => response.json())
        .then((data) => {
          if (data?.product) {
            setProducts(data.product);
            setRelatedProducts(data.related);
            setReviews(data.reviews);
            setSizes(data.sizes);
            setColorVariants(data.colorVariants);
            setSelectedMedia(
              process.env.REACT_APP_BASE_URL + data.product.image
            );
            dispatch(loadingState(false));
            setSelectedSize(data.product.size);
            if (isAuthenticated)
              fetch(`/api/admin/products/review/${data.product.parent_id}`)
                .then((response) => response.json())
                .then((data) => {
                  if (data.length)
                    setShowReview(true);
                  else
                    setShowReview(false);
                }).catch((e) => {
                  setShowReview(false);
                });
          } else {
            setPopupContent2("No Products found");
            setShowPopup2(true);
          }
        });
    }
  }
  useEffect(() => {
    if (match?.params?.pid) getProduct(match?.params?.pid, match?.params?.cid);
  }, [location]);

  const related = {
    dots: false,
    // infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const [activeTab, setActiveTab] = useState("askQuestion");
  const [quesEmail, setQuesEmail] = useState("");
  const [quesName, setQuesName] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [showPopup2, setShowPopup2] = useState(false);
  const [popupContent2, setPopupContent2] = useState("");

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const handlePopupClose2 = () => {
    setShowPopup2(false); // Close the popup
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    // Handle asking a question logic (you can add your own implementation)
    console.log("Question submitted:", questionText);
    if (!questionText) {
      setPopupContent2("Please enter Question.");
      setShowPopup2(true);
    } else if (!quesEmail) {
      setPopupContent2("Please enter Email ID.");
      setShowPopup2(true);
    } else if (!quesName) {
      setPopupContent2("Please enter user name.");
      setShowPopup2(true);
    } else {
      var res = await fetch("/api/admin/category/question", {
        body: JSON.stringify({
          email: quesEmail,
          name: quesName,
          question: questionText,
        }),
        headers: { "Content-Type": "application/json" },
        method: "post",
      });
      if (res.ok) {
        setQuestionText("");
        setQuesName("");
        setQuesEmail("");
        setPopupContent("Question Submitted");
        setShowPopup(true);
      }
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (RattingValue && titleReview && messageReview) {
      var formData = new FormData();
      formData.append("image", file);
      formData.append("title", titleReview);
      formData.append("message", messageReview);
      formData.append("ratting", RattingValue);
      formData.append("product_id", product.parent_id);
      var res = await fetch("/api/admin/products/review", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setTitleReview("");
        setMessageReview("");
        setRattingValue("");
        setFile("");
        setPopupContent("Review Submitted");
        setShowPopup(true);
      }
    } else {
      setPopupContent2("Please fill all values.");
      setShowPopup2(true);
    }
  };

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(false);

  const handleMediaClick = (media, flag) => {
    setSelectedVideo(flag);
    setSelectedMedia(media);
    // Open your right tab or modal here
  };

  const faqData = [
    {
      question: "What if I want to exchange or return my order?",
      answer:
        "Exchange and returns are available for products within 6 days of delivery. Items must be in original condition with all tags intact.",
    },
    {
      question: "I forgot my password. How do I change it?",
      answer:
        "If you have forgotten your password, simply click on ‘Forgot password’ and you will be redirected to an email where you can reset your password.",
    },
    {
      question: "How can I change my personal details or shipping address?",
      answer:
        "Log on to our website and click on ‘My Account’ to change your personal details or shipping address.",
    },
    {
      question: "How do I purchase an item or several items at VastraKala?",
      answer:
        "Online shopping is quite an easy procedure. Start by choosing your items, add them to your shopping bag, and proceed to checkout. An email and text message will be sent to your email address and phone number to verify that you have purchased a product from VastraKala.",
    },
    {
      question: "Still have a query?",
      answer:
        "Didn’t find an answer to a query that you were looking for? Kindly email info@vastrakala.com and we will get back to you within the next 48 hours. You can also contact us at customer care on 919872407145 from Monday-Friday (10am-10pm IST).",
    },
  ];

  const toggleFAQAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleAccordion = (accordion) => {
    switch (accordion) {
      case "Specifications":
        setSepcificationsOpen(!isSpecifications);
        break;
      case "Size":
        setSizeOpen(!isSizeOpen);
        break;
      case "Shipping":
        setShippingOpen(!isShipping);
        break;
      default:
        break;
    }
  };

  const onvariantChange = (id) => {
    getProduct(id, match?.params?.cid);
    match.params.pid = id;
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isSizeModalOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isSizeModalOpen]);

  async function addItems(id) {
    if (isAuthenticated && id) {
      dispatch(addItemsToCart(id, quantity, isAuthenticated, null, selections));
      setPopupContent("Product added to cart");
      setShowPopup(true);
    } else {
      history.push("/LoginSignup");
    }
    console.log(id);
  }

  async function addItems_buy(id) {
    if (isAuthenticated && id) {
      dispatch(addItemsToCart(id, quantity, isAuthenticated, null, selections));
      // setPopupContent("Product added to cart")
      // setShowPopup(true)
      history.push("/cart");
    } else {
      history.push("/LoginSignup");
    }
    console.log(id);
  }

  async function addItemsWish(id) {
    if (isAuthenticated && id) {
      dispatch(addItemsToWishlist(id));
      setPopupContent("Product added to Wishlist");
      setShowPopup(true);
    } else {
      history.push("/LoginSignup");
    }
  }

  const getAccordionIcon = (isOpen) =>
    isOpen ? (
      <i className="fa-solid fa-minus"></i>
    ) : (
      <i className="fa-solid fa-plus"></i>
    );

  return (
    <>
      {/* <Header></Header> */}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <CustomModal
        type="warning"
        content={popupContent2}
        showPopup={showPopup2}
        onClose={handlePopupClose2}
      />
      <div className="SingleProduct p-both-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="productGallery">
                <div className="vertical-media-section">
                  <div
                    onClick={() =>
                      handleMediaClick(
                        process.env.REACT_APP_BASE_URL + product.image,
                        false
                      )
                    }
                    className="productImages"
                  >
                    <img
                      src={process.env.REACT_APP_BASE_URL + product.image}
                      alt={`Media`}
                    />
                  </div>
                  {product?.images?.map((image, index) => {
                    let fileExt = image.split(".").pop();
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          handleMediaClick(
                            process.env.REACT_APP_BASE_URL + image,
                            false
                          )
                        }
                        className="productImages"
                      >
                        {fileExt !== "mp4" ? (
                          <img
                            src={process.env.REACT_APP_BASE_URL + image}
                            alt={`Media ${index + 1}`}
                          />
                        ) : (
                          <video
                            width="100%"
                            height="100%"
                            controls
                            onClick={(e) => {
                              e.preventDefault();
                              handleMediaClick(
                                process.env.REACT_APP_BASE_URL + image,
                                false
                              );
                            }}
                          >
                            <source
                              src={process.env.REACT_APP_BASE_URL + image}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                    );
                  })}
                  {product.video ? (
                    <div
                      onClick={() => handleMediaClick(product.video, true)}
                      className="productImages"
                    >
                      <img
                        src={process.env.REACT_APP_BASE_URL + product.image}
                        alt={`Media Video`}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="69"
                        height="69"
                        viewBox="0 0 69 69"
                        fill="none"
                      >
                        <circle cx="34.5" cy="34.5" r="34.5" fill="white" />
                        <path
                          d="M46 34.5L28.75 44.4593L28.75 24.5407L46 34.5Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {selectedMedia ? (
                  <div className="right-tab-or-modal">
                    {!selectedVideo ? (
                      <MyReactImageMagnify
                        smallImage={{
                          alt: selectedMedia,
                          isFluidWidth: true,
                          src: selectedMedia,
                        }}
                        largeImage={{
                          src: selectedMedia,
                          width: 1000,
                          height: 1200,
                        }}
                      />
                    ) : (
                      <iframe
                        width="100%"
                        height="100%"
                        src={selectedMedia}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="SingleProductDetails">
                <h2 className="proName mb-2">{product.name}</h2>
                <span className="sku mb-2">Style No {product.style_no}</span>
                <p className="proPrice mb-2">
                  {product.regular_price !== product.selling_price ? (
                    match?.params?.cid === "Turban" ? <></> :
                      <span className="slashed">Rs. {product.regular_price}</span>
                  ) : (
                    ""
                  )}{" "}
                  {match?.params?.cid === "Turban" ? "Rs. " + product.selling_price + " - Rs. " + (product.selling_price * 9) + " " : "Rs. " + product.selling_price + " "}
                  {product.regular_price !== product.selling_price ? (
                    <span className="offPercent">
                      ({""}
                      {Math.round(
                        ((product.regular_price - product.selling_price) /
                          product.regular_price) *
                        100
                      )}
                      {""}% OFF)
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="col-md-12 mb-2">
                  <p className="dilverBy">
                    <i className="fa-solid fa-truck-fast"></i> Est Delivery by:{" "}
                    <b>2-4 Weeks</b>
                  </p>
                </div>
                <hr />
                <div className="row mb-2 align-items-center">
                  {/* <div className="col-md-12">
                    <label htmlFor="" className="selectedSize">
                      Size: <b>{product.size}</b>
                    </label>
                  </div> */}

                  {match?.params?.cid === "Turban" ? <>
                    <div className="col-md-6">
                      <label>Size: <b>Per Meter</b></label>
                      <select
                        // value={turbanSelectedSize}
                        //   onChange={(e) => setTurbanSelectedSize(e.target.value)}
                        value={selections.turbanLength}
                        onChange={(e) =>
                          handleChange_customization(e, "turbanLength")
                        }
                      >
                        <option selected value="1">1</option>
                        <option value="1.25">1.25</option>
                        <option value="1.5">1.5</option>
                        <option value="1.75">1.75</option>
                        <option value="2">2</option>
                        <option value="2.25">2.25</option>
                        <option value="2.5">2.5</option>
                        <option value="2.75">2.75</option>
                        <option value="3">3</option>
                        <option value="3.25">3.25</option>
                        <option value="3.5">3.5</option>
                        <option value="3.75">3.75</option>
                        <option value="4">4</option>
                        <option value="4.25">4.25</option>
                        <option value="4.5">4.5</option>
                        <option value="4.75">4.75</option>
                        <option value="5">5</option>
                        <option value="5.25">5.25</option>
                        <option value="5.5">5.5</option>
                        <option value="5.75">5.75</option>
                        <option value="6">6</option>
                        <option value="6.25">6.25</option>
                        <option value="6.5">6.5</option>
                        <option value="6.75">6.75</option>
                        <option value="7">7</option>
                        <option value="7.25">7.25</option>
                        <option value="7.5">7.5</option>
                        <option value="7.75">7.75</option>
                        <option value="8">8</option>
                        <option value="8.25">8.25</option>
                        <option value="8.5">8.5</option>
                        <option value="8.75">8.75</option>
                        <option value="9">9</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label>Stitching* (Pico)</label>
                      <select
                        // value={turbanPico}
                        //   onChange={(e) => setTurbanPico(e.target.value)}
                        value={selections.Pico}
                        onChange={(e) =>
                          handleChange_customization(e, "Pico")
                        }
                      >
                        <option selected value="50">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div></> : <div className="col-md-4">
                    <label>Select Size</label>
                  </div>}




                  {!excludedCategories.includes(match?.params?.cid) ?
                    <div className="col-md-8">
                      <p>
                        {" "}
                        <span onClick={openModal} className="S_Guide">
                          Size Guide
                        </span>
                        {product?.customization ? (
                          <Link
                            className="ccSize"
                            to={{
                              pathname: "/CustomSize/" + product.name,
                              state: {
                                id: product.id,
                                name: product.name,
                                parent_id: product.parent_id,
                                category: product.category,
                                customization_values:
                                  product?.customization_values,
                                tag: product?.tag,
                                image: product?.image,
                              },
                            }}
                          >
                            | {" "}
                            Custom Make to Your Size
                          </Link>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    : <></>}
                  {match?.params?.cid === "Turban" ? <></> : <>
                    <div className="col-md-12">
                      <div className="newSizeButtons">
                        {sizes.map((size) => {
                          return (
                            <div className="sizeWithToolTip">
                              <button
                                onClick={() => onvariantChange(size.id)}
                                className={
                                  size.size === product.size ? "active" : ""
                                }
                              // value={size.size}
                              // selected={size.size == product.size ? true : false}
                              >
                                {" "}
                                {size.size}
                              </button>
                              <div className="SizeTip">
                                <p>{size.tooltip}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div></>}

                  {isSizeModalOpen ? (
                    <div className="modal ssGuide">
                      <div className="modal-content" ref={modalRef}>
                        <button className="closeModal" onClick={closeModal}>
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                        <div className="tab-bar">
                          <button
                            onClick={() => changeTab("tab1")}
                            className={
                              modalactiveTab === "tab1" ? "active" : ""
                            }
                          >
                            Size Guide
                          </button>
                          <button
                            onClick={() => changeTab("tab2")}
                            className={
                              modalactiveTab === "tab2" ? "active" : ""
                            }
                          >
                            Measuring Guide
                          </button>
                          <button
                            onClick={() => changeTab("tab3")}
                            className={
                              modalactiveTab === "tab3" ? "active" : ""
                            }
                          >
                            HOW TO MEASURE
                          </button>
                          {/* Add more tabs as needed */}
                        </div>
                        <div className="tab-content">
                          {modalactiveTab === "tab1" && (
                            <div>
                              <div className="toggle-container">
                                <label className="toggle-label">Inches</label>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={toggleUnits}
                                    checked={isCentimeters}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <label className="toggle-label">
                                  Centimeters
                                </label>
                              </div>
                              <p>
                                <img
                                  src={
                                    isCentimeters ? cm_Chart : sizeGuideInches
                                  }
                                />
                              </p>
                            </div>
                          )}
                          {modalactiveTab === "tab2" && (
                            <img src={measuringGuideWomen} />
                          )}
                          {modalactiveTab === "tab3" && (
                            <iframe
                              className="custom_size_video"
                              width="560"
                              height="315"
                              src="https://www.youtube.com/embed/4Jy2xZsTDtk?si=r5TT5Eb27nB3rQY3"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          )}
                        </div>
                        <div className="modalFooter">
                          <h3>
                            <i class="fa-brands fa-whatsapp"></i> Whatsapp Us at{" "}
                            <a href="tel: +91 98724 07145"> +91 98724 07145</a>{" "}
                            if you are unsure of your size.
                          </h3>
                          <p>
                            This is a standard size guide for the basic body
                            measurements. Length will vary according to style.
                            There may also be variations in some brands commonly
                            with Indian clothing, so please refer to the product
                            measurements displayed on the product page.
                            Alternatively, you may contact our customer care for
                            specific queries at{" "}
                            <a href="mailto:info@vastrakala.com">
                              info@vastrakala.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {match?.params?.cid === "Turban" ? <></> : <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="customizationTabble">
                      <table>
                        {product?.is_lehenga_waist && (
                          <tr>
                            <td>
                              <label>Lehenga waist</label>
                            </td>
                            <td>
                              <select
                                value={selections.lehengaWaist}
                                onChange={(e) =>
                                  handleChange_customization(e, "lehengaWaist")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="0-XS-(waist 27)">
                                  0-XS-(waist 27)
                                </option>
                                <option value="2-XS-(waist-28)">
                                  2-XS-(waist-28)
                                </option>
                                <option value="4-Small-(Waist-29)">
                                  4-Small-(Waist-29)
                                </option>
                                <option value="6-Small-(Waist-30)">
                                  6-Small-(Waist-30)
                                </option>
                                <option value="8-Medium-(Waist-31)">
                                  8-Medium-(Waist-31)
                                </option>
                                <option value="10-Medium-(Waist-32)">
                                  10-Medium-(Waist-32)
                                </option>
                                <option value="12-Large-(Waist 33.5)">
                                  12-Large-(Waist 33.5)
                                </option>
                                <option value="14-Large-(Waist-35)">
                                  14-Large-(Waist-35)
                                </option>
                                <option value="16-XL-(Waist 36)">
                                  16-XL-(Waist 36)
                                </option>
                                <option value="17-XL-(Waist-37)">
                                  17-XL-(Waist-37)
                                </option>
                                <option value="18-XXL-(Waist-39)">
                                  18-XXL-(Waist-39)
                                </option>
                                <option value="19-XXL-(Waist-41)">
                                  19-XXL-(Waist-41)
                                </option>
                                <option value="20-XXXL-(Waist-42)">
                                  20-XXXL-(Waist-42)
                                </option>
                                <option value="21-XXXL-(Waist-43)">
                                  21-XXXL-(Waist-43)
                                </option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_lehenga_length && (
                          <tr>
                            <td>
                              <label>
                                Lehenga Length (waist to floor including Heels)
                              </label>
                            </td>
                            <td>
                              <select
                                value={selections.lehengaLength}
                                onChange={(e) =>
                                  handleChange_customization(e, "lehengaLength")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="36 inch (91cm)">
                                  36 inch (91cm)
                                </option>
                                <option value="38 inch (96cm)">
                                  38 inch (96cm)
                                </option>
                                <option value="39 inch (99cm)">
                                  39 inch (99cm)
                                </option>
                                <option value="40 inch (101cm)">
                                  40 inch (101cm)
                                </option>
                                <option value="41 inch (104cm)">
                                  41 inch (104cm)
                                </option>
                                <option value="42 inch (106cm)">
                                  42 inch (106cm)
                                </option>
                                <option value="43 inch (109cm)">
                                  43 inch (109cm)
                                </option>
                                <option value="44 inch (111cm)">
                                  44 inch (111cm)
                                </option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_height && (
                          <tr>
                            <td>
                              <label>
                                Select Your Height (Including Heels)
                              </label>
                            </td>
                            <td>
                              <select
                                value={selections.height}
                                onChange={(e) =>
                                  handleChange_customization(e, "height")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="4 feet 5 inch (134cm)">
                                  4 feet 5 inch (134cm)
                                </option>
                                <option value="4 feet 6 inch (137cm)">
                                  4 feet 6 inch (137cm)
                                </option>
                                <option value="4 feet 7 inch (140cm)">
                                  4 feet 7 inch (140cm)
                                </option>
                                <option value="4 feet 8 inch (142cm)">
                                  4 feet 8 inch (142cm)
                                </option>
                                <option value="4 feet 9 inch (145cm)">
                                  4 feet 9 inch (145cm)
                                </option>
                                <option value="4 feet 10 inch (147cm)">
                                  4 feet 10 inch (147cm)
                                </option>
                                <option value="4 feet 11 inch (150cm)">
                                  4 feet 11 inch (150cm)
                                </option>
                                <option value="5 feet 0 inch (152cm)">
                                  5 feet 0 inch (152cm)
                                </option>
                                <option value="5 feet 1 inch (155cm)">
                                  5 feet 1 inch (155cm)
                                </option>
                                <option value="5 feet 2 inch (157cm)">
                                  5 feet 2 inch (157cm)
                                </option>
                                <option value="5 feet 3 inch (160cm)">
                                  5 feet 3 inch (160cm)
                                </option>
                                <option value="5 feet 4 inch (162cm)">
                                  5 feet 4 inch (162cm)
                                </option>
                                <option value="5 feet 5 inch (165cm)">
                                  5 feet 5 inch (165cm)
                                </option>
                                <option value="5 feet 6 inch (168cm)">
                                  5 feet 6 inch (168cm)
                                </option>
                                <option value="5 feet 7 inch (170cm)">
                                  5 feet 7 inch (170cm)
                                </option>
                                <option value="5 feet 8 inch (173cm)">
                                  5 feet 8 inch (173cm)
                                </option>
                                <option value="5 feet 9 inch (175cm)">
                                  5 feet 9 inch (175cm)
                                </option>
                                <option value="5 feet 10 inch (178cm)">
                                  5 feet 10 inch (178cm)
                                </option>
                                <option value="5 feet 11 inch (180cm)">
                                  5 feet 11 inch (180cm)
                                </option>
                                <option value="6 feet 0 inch (183cm)">
                                  6 feet 0 inch (183cm)
                                </option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_sleeve_length && (
                          <tr>
                            <td>
                              <label>Sleeve Length</label>
                            </td>
                            <td>
                              <select
                                value={selections.sleeveLength}
                                onChange={(e) =>
                                  handleChange_customization(e, "sleeveLength")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="Cap Sleeve">Cap Sleeve</option>
                                <option value="Half Sleeve">Half Sleeve</option>
                                <option value="Three Quarter Sleeve">
                                  Three Quarter Sleeve
                                </option>
                                <option value="Full Sleeve">Full Sleeve</option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_padding && (
                          <tr>
                            <td>
                              <label>Padding Required?</label>
                            </td>
                            <td>
                              <select
                                value={selections.padding}
                                onChange={(e) =>
                                  handleChange_customization(e, "padding")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_saree_length && (
                          <tr>
                            <td>
                              <label>Saree Length</label>
                            </td>
                            <td>
                              <select
                                value={selections.sareeLength}
                                onChange={(e) =>
                                  handleChange_customization(e, "sareeLength")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="36 inch (91cm)">
                                  36 inch (91cm)
                                </option>
                                <option value="38 inch (96cm)">
                                  38 inch (96cm)
                                </option>
                                <option value="39 inch (99cm)">
                                  39 inch (99cm)
                                </option>
                                <option value="40 inch (101cm)">
                                  40 inch (101cm)
                                </option>
                                <option value="41 inch (104cm)">
                                  41 inch (104cm)
                                </option>
                                <option value="42 inch (106cm)">
                                  42 inch (106cm)
                                </option>
                                <option value="43 inch (109cm)">
                                  43 inch (109cm)
                                </option>
                                <option value="44 inch (111cm)">
                                  44 inch (111cm)
                                </option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_saree_waist && (
                          <tr>
                            <td>
                              <label>Saree Waist</label>
                            </td>
                            <td>
                              <select
                                value={selections.sareeWaist}
                                onChange={(e) =>
                                  handleChange_customization(e, "sareeWaist")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="2-XS-(Waist-28)">
                                  2-XS-(Waist-28)
                                </option>
                                <option value="4-Small-(Waist-29)">
                                  4-Small-(Waist-29)
                                </option>
                                <option value="6-Small-(Waist-30)">
                                  6-Small-(Waist-30)
                                </option>
                                <option value="8-Medium-(Waist-31)">
                                  8-Medium-(Waist-31)
                                </option>
                                <option value="10-Medium-(Waist-32)">
                                  10-Medium-(Waist-32)
                                </option>
                                <option value="12-Large-(Waist 33.5)">
                                  12-Large-(Waist 33.5)
                                </option>
                                <option value="14-Large-(Waist-35)">
                                  14-Large-(Waist-35)
                                </option>
                                <option value="16-XL-(Waist 36)">
                                  16-XL-(Waist 36)
                                </option>
                                <option value="17-XL-(Waist-37)">
                                  17-XL-(Waist-37)
                                </option>
                                <option value="18-XXL-(Waist-39)">
                                  18-XXL-(Waist-39)
                                </option>
                                <option value="19-XXL-(Waist-41)">
                                  19-XXL-(Waist-41)
                                </option>
                                <option value="0-XS-(Waist 27)">
                                  0-XS-(Waist 27)
                                </option>
                                <option value="21-XXXL-(Waist-43)">
                                  21-XXXL-(Waist-43)
                                </option>
                              </select>
                            </td>
                          </tr>
                        )}
                        {product?.is_petti_coat && (
                          <tr>
                            <td>
                              <label>Petti Coat</label>
                            </td>
                            <td>
                              <select
                                value={selections.pettiCoat}
                                onChange={(e) =>
                                  handleChange_customization(e, "pettiCoat")
                                }
                              >
                                <option value="">Please Select</option>
                                <option value="Yes">
                                  Yes
                                </option>
                                <option value="No , I Would Skip">
                                  No , I Would Skip
                                </option>
                              </select>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                      <label htmlFor="#">
                        Select Your Height (Including heels) *
                      </label>
                      <select name="#" id="#">
                        <option value="#">Please Select</option>
                        <option value="#">150cm</option>
                        <option value="#">160cm</option>
                        <option value="#">170cm</option>
                        <option value="#">180cm</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="#">Padding Required?*</label>
                      <select name="#" id="#">
                        <option value="#">Please Select</option>
                        <option value="#">Yes</option>
                        <option value="#">No</option>
                      </select>
                    </div> */}
                </div>}

                <div className="col-md-12">
                  <label htmlFor="" className="selectedSize">
                    Color: <b>{product.color}</b>
                  </label>
                </div>
                <div className="productColorVariations mb-2">
                  {colorVariants.map((variant) => {
                    return (
                      <div
                        className="varPic active"
                        onClick={() => onvariantChange(variant.image[0].id)}
                      >
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            variant.image[0].image
                          }
                          width="100%"
                        />
                        <p>{variant.name}</p>
                      </div>
                    );
                  })}
                </div>
                {match?.params?.cid === "Turban" ? <div className="totalPrice">{"Rs. " + (Number(product.selling_price * Number(selections.turbanLength)) + Number(selections.Pico))}</div> : ""}
                <div className="col-md-12 mb-2">
                  <label htmlFor="" className="selectedSize">
                    Quantity:
                  </label>
                  <div className="row">
                    <div className="col-4">
                      <div className="quantity">
                        <div className="number">
                          <span className="minus" onClick={decreaseQuantity}>
                            <i className="fa-solid fa-minus"></i>
                          </span>
                          <input type="text" value={quantity} placeholder="0" />
                          <span className="plus" onClick={increaseQuantity}>
                            <i className="fa-solid fa-plus"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="addWish">
                        {!wishlist_ids.includes(product?.id?.toString()) ? (
                          <button onClick={(e) => addItemsWish(product.id)}>
                            <i className="fa-regular fa-heart"></i>Add to
                            Wishlist
                          </button>
                        ) : (
                          <Link
                            to={{
                              pathname: `../../../../../../../UserAccount`,
                              state: {
                                page: 4,
                              },
                            }}
                            className="addwish"
                          >
                            <i className="fa-regular fa-heart"></i> View
                            Wishlist
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="addCartBtns col-md-12 mb-2">
                  {!cart_ids.includes(product?.id?.toString()) ? (
                    <button
                      className="addBag"
                      onClick={(e) => addItems(product.id)}
                    >
                      Add To Bag
                    </button>
                  ) : (
                    <Link to="cart" className="addBag">
                      View cart
                    </Link>
                  )}
                </div>
                <div className="col-md-12 mb-2">
                  <button
                    className="bNow"
                    onClick={(e) => addItems_buy(product.id)}
                  >
                    Buy Now
                  </button>
                </div>

                <div className="col-md-12 mb-2">
                  <ul className="social">
                    <li>
                      <label htmlFor="#">Share:</label>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={encodedUrl}
                        quote={"Hi, I found this style what do you think "}
                      >
                        <a>
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </FacebookShareButton>
                    </li>
                    <li>
                      <PinterestShareButton
                        url={encodedUrl}
                        media={process.env.REACT_APP_BASE_URL + product.image}
                      >
                        <a>
                          <i className="fa-brands fa-pinterest"></i>
                        </a>
                      </PinterestShareButton>
                    </li>
                    <li>
                      <TwitterShareButton
                        url={encodedUrl}
                        title={"Hi, I found this style what do you think "}
                      >
                        <a>
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </TwitterShareButton>
                    </li>
                    <li>
                      <WhatsappShareButton
                        url={encodedUrl}
                        title={"Hi, I found this style what do you think "}
                      >
                        <a>
                          <i className="fa-brands fa-whatsapp"></i>
                        </a>
                      </WhatsappShareButton>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-md-12 mb-2">
                  <p className="shortDisc">
                    <div
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </p>
                </div> */}
                <div className="col-md-12 mb-2">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <p>
                        <i className="fa-solid fa-percent"></i> 100% Purchase
                        Protection
                      </p>
                    </div>
                    <div className="col-md-6 mb-2">
                      <p>
                        <i className="fa-solid fa-retweet"></i> 48 hours easy
                        returns.
                      </p>
                    </div>
                    <div className="col-md-6 mb-2">
                      <p>
                        <i className="fa-regular fa-star"></i> Assured Quality
                      </p>
                    </div>
                    {/* <div className="col-md-6 mb-2">
                      <p>
                        <i className="fa-solid fa-truck-arrow-right"></i> Free
                        shipping*
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <div className="filterCol">
                    <div
                      className="coltitle"
                      onClick={() => toggleAccordion("Specifications")}
                    >
                      Product Details{" "}
                      <div className="accordiantoggle">
                        {getAccordionIcon(isSpecifications)}
                      </div>
                    </div>
                    {isSpecifications ? (
                      <div className="filterWrapper">
                        <div className="singleFilter">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.description,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="filterCol">
                    <div
                      className="coltitle"
                      onClick={() => toggleAccordion("Size")}
                    >
                      Style & Fit Tips{" "}
                      <div className="accordiantoggle">
                        {getAccordionIcon(isSizeOpen)}
                      </div>
                    </div>
                    {isSizeOpen ? (
                      <div className="filterWrapper">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.rich_description,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="filterCol">
                    <div
                      className="coltitle"
                      onClick={() => toggleAccordion("Shipping")}
                    >
                      Shipping & returns{" "}
                      <div className="accordiantoggle">
                        {getAccordionIcon(isShipping)}
                      </div>
                    </div>
                    {isShipping ? (
                      <div className="filterWrapper">
                        <p>
                          <b>Delivery & returns</b>
                          <br></br>
                          Exchange and returns are available for products within
                          6 days of delivery. Items must be in original
                          condition with all tags intact. <br />
                          Read more about <Link to="/Return">Return Policy</Link>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="prodReview p-both-50">
        <div className="container">
          {isAuthenticated ? (
            <div className="row">
              <div className="col-md-12">
                <div className="secTitles">
                  <h2 className="title">Product Review</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="rivewWrapper">
                  <div className="row">
                    {reviews.length > 0 ? (
                      reviews.map((review, index) => {
                        return (
                          <div key={index} className="col-md-6">
                            <div className="reviewSingle">
                              <div className="revHeader">
                                <div className="revUserData">
                                  <img src={categoryimg} alt="" />
                                  <p>
                                    <b>{review.name + " " + review.last_name}</b>{" "}
                                    - {getFormattedDate(review.review_date)}
                                  </p>
                                </div>
                                <div className="revRating">
                                  <i
                                    class={`fa-${review.rating >= 1 ? "solid" : "regular"
                                      } fa-star`}
                                  ></i>
                                  <i
                                    class={`fa-${review.rating >= 2 ? "solid" : "regular"
                                      } fa-star`}
                                  ></i>
                                  <i
                                    class={`fa-${review.rating >= 3 ? "solid" : "regular"
                                      } fa-star`}
                                  ></i>
                                  <i
                                    class={`fa-${review.rating >= 4 ? "solid" : "regular"
                                      } fa-star`}
                                  ></i>
                                  <i
                                    class={`fa-${review.rating >= 5 ? "solid" : "regular"
                                      } fa-star`}
                                  ></i>
                                </div>
                              </div>
                              <div className="revBody">
                                <p>{review.comment}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="review_notfound">
                        Be the first one to review this product.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {
            <div className="row">
              <div className="col-md-12">
                <div className="askQuestion">
                  <div className="quesBtns">
                    <button
                      onClick={() => handleTabChange("askQuestion")}
                      className={activeTab === "askQuestion" ? "active" : ""}
                    >
                      Ask a Question
                    </button>
                    {showReview ? (
                      <button
                        onClick={() => handleTabChange("writeReview")}
                        className={activeTab === "writeReview" ? "active" : ""}
                      >
                        Write a Review
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="quesTabData">
                    {activeTab === "askQuestion" ? (
                      <form onSubmit={handleQuestionSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <label>Name</label>
                            <input
                              type="text"
                              value={quesName}
                              onInput={(e) => setQuesName(e.target.value)}
                            // disabled
                            // value={user.first_name + " " + user.last_name}
                            // value={questionText}
                            // onChange={(e) => setQuestionText(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Email</label>
                            <input
                              type="email"
                              value={quesEmail}
                              onInput={(e) => setQuesEmail(e.target.value)}
                            // disabled value={user.email}
                            />
                          </div>
                          <div className="col-md-12">
                            <label>Question</label>
                            <textarea
                              name="#"
                              id="#"
                              cols="30"
                              rows="5"
                              placeholder="Write Your Question Here."
                              value={questionText}
                              onChange={(e) => setQuestionText(e.target.value)}
                            ></textarea>
                          </div>
                        </div>

                        <button
                          type="submit"
                          disabled={
                            questionText && quesEmail && quesName ? false : true
                          }
                        >
                          Submit Question
                        </button>
                      </form>
                    ) : (
                      ""
                    )}
                    {isAuthenticated ? (
                      showReview && activeTab === "writeReview" ? (
                        <form onSubmit={handleReviewSubmit}>
                          {/* <label>
                        Review:
                        <textarea
                          value={reviewText}
                          onChange={(e) => setReviewText(e.target.value)}
                        />
                      </label> */}
                          <div className="row">
                            <div className="col-md-6">
                              <label>Name</label>
                              <input
                                type="text"
                                disabled
                                value={user.first_name + " " + user.last_name}
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Email</label>
                              <input type="email" disabled value={user.email} />
                            </div>
                            <div className="col-md-12">
                              <label>Title Of Review</label>
                              <input
                                type="text"
                                value={titleReview}
                                onInput={(e) => setTitleReview(e.target.value)}
                              />
                            </div>
                            <div className="col-md-12">
                              <label>Add a Photo or Video</label>
                              <input type="file" onChange={handleChange} />
                            </div>
                            <div className="col-md-12">
                              <label>Overall Rating</label>
                              <div className="oRate">
                                <i
                                  className={`fa-${RattingValue >= 1 ? "solid" : "regular"
                                    } fa-star`}
                                  onClick={(e) => setRattingValue(1)}
                                ></i>
                                <i
                                  className={`fa-${RattingValue >= 2 ? "solid" : "regular"
                                    } fa-star`}
                                  onClick={(e) => setRattingValue(2)}
                                ></i>
                                <i
                                  className={`fa-${RattingValue >= 3 ? "solid" : "regular"
                                    } fa-star`}
                                  onClick={(e) => setRattingValue(3)}
                                ></i>
                                <i
                                  className={`fa-${RattingValue >= 4 ? "solid" : "regular"
                                    } fa-star`}
                                  onClick={(e) => setRattingValue(4)}
                                ></i>
                                <i
                                  className={`fa-${RattingValue >= 5 ? "solid" : "regular"
                                    } fa-star`}
                                  onClick={(e) => setRattingValue(5)}
                                ></i>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label>How is your overall experience ?</label>
                              <textarea
                                name="#"
                                id="#"
                                cols="30"
                                rows="5"
                                placeholder="Write Your Question Here."
                                value={messageReview}
                                onInput={(e) =>
                                  setMessageReview(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                          <button type="submit">Submit Review</button>
                        </form>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="row">
            <div className="col-md-12">
              <div className="faqBox">
                <h2>FAQ's</h2>
                {faqData.map((item, index) => (
                  <div key={index} className="faqItem">
                    <div
                      className={`faqQuestion ${activeIndex === index ? "active" : ""
                        }`}
                      onClick={() => toggleFAQAccordion(index)}
                    >
                      {item.question}{" "}
                      <div className="faqAccordianSymbol">
                        {activeIndex === index ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </div>
                    </div>
                    {activeIndex === index ? (
                      <div className="faqAnswer">{item.answer}</div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {relatedProducts.length ? (
        <div className="relatedProducts p-both-50">
          <div className="container">
            <div className="row">
              <div className="secTitles">
                <h2 className="title">Related Products</h2>
              </div>
            </div>
            <div className="row">
              <Slider {...related}>
                {relatedProducts
                  ? relatedProducts.map((product, index) => {
                    let url = `/singleproduct/${product.name}/p/${product.product_id}`;
                    return (
                      <div key={index}>
                        <div className="singleCatProduct">
                          <div className="prodTop">
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL + product.image
                              }
                              width="100%"
                              onClick={() =>
                                handleClick(url, product.product_id)
                              }
                            />
                            <div className="hoverButtons">
                              <a
                                onClick={(e) => addItems(product.product_id)}
                                href="#"
                                className="addcart"
                              >
                                Add to Cart
                              </a>
                              <a
                                onClick={(e) =>
                                  addItemsWish(product.product_id)
                                }
                                href="#"
                                className="addwish"
                              >
                                Add to Wishlist
                              </a>
                            </div>
                          </div>
                          <h2 className="productTitle">{product.name}</h2>
                          <div className="rating">
                            <ul>
                              <li>
                                <i
                                  class={`fa-${product.rating >= 1 ? "solid" : "regular"
                                    } fa-star`}
                                ></i>
                              </li>
                              <li>
                                <i
                                  class={`fa-${product.rating >= 2 ? "solid" : "regular"
                                    } fa-star`}
                                ></i>
                              </li>
                              <li>
                                <i
                                  class={`fa-${product.rating >= 3 ? "solid" : "regular"
                                    } fa-star`}
                                ></i>
                              </li>
                              <li>
                                <i
                                  class={`fa-${product.rating >= 4 ? "solid" : "regular"
                                    } fa-star`}
                                ></i>
                              </li>
                              <li>
                                <i
                                  class={`fa-${product.rating >= 5 ? "solid" : "regular"
                                    } fa-star`}
                                ></i>
                              </li>
                            </ul>
                            <span className="RatingCount">
                              ({product.rating})
                            </span>
                          </div>
                          <p className="productPrice">
                            ${product.selling_price}
                          </p>
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="footerTop">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-regular fa-paper-plane"></i>
                <p>We ship worldwide</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-brands fa-whatsapp"></i>
                <p>Chat on Whatsapp</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-solid fa-mobile-screen-button"></i>
                <p>
                  Call & Mail us to book a styling <br />
                  appointment +91 9872407145
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default SingleProduct;
